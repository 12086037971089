.rect-crc{
    position: relative;
    background-color: rgb(166, 164, 164);
    width: 100%;
    height: 600px;
    margin: 110px 0 0 50px ;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0 none;
}
.crc-footer{
    position: absolute;
    width: 100%;
    top:60rem;
}
@media(max-width:768px){
    .rect-crc{
        width: 600px;
    }
}
@media(max-width:440px){
    .rect-crc{
        margin-top: 100px;
        width: 350px;
    }
    .crc-footer{
        top:30rem;
    }
}