.crc-nav-bar nav{
    margin: 4rem 3rem 1rem 5rem;
    
}
.crc-nav-bar nav div img{
    width: 90px;
    margin-bottom: 7px;
}
.crc-nav-bar nav div ul a{
    text-decoration: none;
    color: black;
}

.dropdown-navbar{
    position: relative !important;
    display: inline-block !important;
}
.dropdown-content-navbar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute !important;
    min-width: 100px !important;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2) !important;
    padding: 12px 16px !important;
    z-index: 1 !important;
    background-color: #232B2B !important;
    color: #fff;
    
  }
.dropdown-content-navbar a {
    color: #fff !important;
}
@media(max-width:768px){
    .anchor{
        font-size: 0.6rem;
    }
    .dropdown-content-navbar{
        min-width: 50px !important;
    }
}
@media(max-width:440px){
    .crc-nav-bar nav div img{
        width: 40px;
    }
    .anchor{
        font-size: 0.4rem;
    }
    .crc-nav-bar{
        background: #FFD800;
    }
    .crc-nav-bar nav{
        margin-top: 0;
    }
}