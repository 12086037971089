.crcform-img{
    width:2400px;
    height: 1600px;
}
.logindiv3{
    background-color: #fff;
    width: 930px;
    height:1200px;
    opacity: 90%;
    z-index: 1;
    position: absolute;
    top:40%;
    left: 30%;
    border-radius: 20px;
    padding: 70px;
}
.crcdiv{
    width:700px
}
.submit-btn{
    width: 140px;
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
    
}
.crc-footer{
    position: absolute;
    top:66rem;
}
.crc-p{
    font-size: 1.1rem;
    font-weight: 500;
    width: 600px;
}

.form-input{
    width:300px;
    border:1px solid #FFD800;
    background-color: #fff;
    height:2.5rem;
    border-radius:8px
}
.form-div div{
    border:1px solid #FFD800;
    background-color: #fff;
    height:2.5rem;
    border-radius:8px;
    width:60px
}

.bg-yellow{
    background-color:#FFD800 !important ;
}
@media(max-width:768px){
    .crcform-img{
        display: none;
    }
    .logindiv3{
        background: none;
        left: 0;
        top:15%;
        padding-top: 0;
    }
    .logindiv3 h4{
        width: 300px;
    }
    .crcdiv{
        width:300px
    }
    .crc-content{
        display: flex !important;
        flex-direction: column !important;
    }
    .crc-form{
        margin-top: 110rem;
    }
    
}
@media(max-width:440px){
    .crcform-img{
        display: none;
    }
    .logindiv3{
        background: none;
        left: 0;
        top:15%;
        padding-top: 0;
    }
    .logindiv3 h4{
        width: 300px;
    }
    .crcdiv{
        width:300px
    }
    .crc-content{
        display: flex !important;
        flex-direction: column !important;
    }
    .crc-form{
        margin-top: 110rem;
    }
    .submit-btn{

    }
    
}
