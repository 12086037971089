.logindiv1{
    background-color: rgba(255, 255, 255,0.9);
    width: fit-content;
    z-index: 1;
    position: absolute;
    top:45%;
    left: 18%;
    border-radius: 20px;
    padding: 70px;
}
.login img{
    width: 2300px;
    height: 950px;
}
.dottedline{
    border-left: 2px dotted black;
    height:25rem;
}
.logindiv1 div form{
    display: flex;
    flex-direction: column;
}
.logindiv1 div form input{
    border: 2px solid #FFD800;
    background-color: #fff;
    border-radius: 8px;
    width: 350px;
    height: 35px;
}
.logindiv1 div a{
    color: #000;
    text-decoration: underline;
}
.login-button{
    width: 140px;
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
    

    
}
.p1{
    font-size: 1.3rem;
    width: 400px;
}

.logindiv1 div form select{
    border: 2px solid #FFD800;
    background-color: #fff;
    border-radius: 8px;
    width: 350px;
    height: 35px;

}
@media(max-width:768px){
    .login img{
        width:770px;
        height: 1300px;
        margin: 0;
    }
    .logindiv1{
        display: flex;
        flex-direction: column;
        left: 15%;
    }
    .dottedline{
        height: 0;
        border-bottom: dotted black;
    }
    .login-foot{
        margin: 0;
    }
   
}
@media(max-width:440px){
    .logindiv1{
        background: none;
        left: 0;
        top:15%;
        padding-right: 0;
    }
    .login img{
        display: none;
    }
    .login-foot{
        margin-top: 60rem;
    }
    .logindiv1 div form input{
        width: 250px;
    }
    .logindiv1 div form select{
        width: 250px;

    }
    .p1{
        width: 300px;
    }
    
}
