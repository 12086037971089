.support-group-main{
    display: grid;
    grid-template-columns: 0.35fr 1fr;
    box-sizing: border-box;
    background-color: #E6F4F7;
}
.support-group-sidebar{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2rem;
    background-color: #BDEEFF;
    padding: 1rem;
    border-radius: 0 1rem 1rem 0;
}
.group-item{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: white;
    width: 80%;
    height: 2.5rem;
    border-radius: 0.5rem;
    color: rgba(128, 128, 128,0.8);
}

.group-active{
    background-color:#FFD800 !important;
    color:black !important;
}

.support-group-body{
background-color: #E6F4F7;
padding:2rem;
}

.group-body-head{
    font-weight:600;
    margin-bottom:2rem;
} 
.group-content{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:3rem;
    place-items:center;
    padding: 0 5rem;
    margin-bottom:4rem;
}

.grid-col-span-2{
    grid-column: span 2;
}
.group-content-para{
    text-align:justify;
}
.group-content-boxs{
    display:flex;
    flex-direction: column;
    gap:2rem;
    width:100%;
    overflow:hidden;
}

.group-content-box{
    width:78%;
    height:11rem;
    background-color:#D9D9D9 ;
    display: flex;
    column-gap: 5px;
}

.community-platform{
    display:flex;
    flex-direction:column;
    padding:1rem 5rem;
}

.community-comments{
    display:flex;
    flex-direction: column;
    width:100%;
}

.community-comment-box{
    display:flex;
    flex-direction: column;
    justify-content:flex-end;
    padding:1rem;
    background-color: white;
    box-shadow: 0 0.5rem 0.5rem rgba(128,128,128,0.5);
}

.comment-head{
    display:flex;
    align-items:center;
    justify-content:space-between;
}

.comment-time-head{
    display:flex;
    align-items:center;
    gap:1rem;
}

.comment-head{
    font-size:1.1rem;
    font-weight:600;
}

.comment-time{
    font-size:0.8rem;
    color:grey;
}

.comment-body{
    margin-top:1rem;
    text-align:justify;
}

.comment-foot{
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-weight:600;
    margin-top:0.7rem;
}

.community-reply-box{
    display:flex;
    flex-direction:column;
    gap:1rem;
    align-self: flex-end;
    margin-top:1rem ;
    margin-bottom:2rem;
    width:90%;
}

.community-chat-input{
    display: flex;
    margin-top: 4rem;
    align-items: center;
    justify-content: center;
    
}

.community-chat-input .input-box{
    display: flex;
    align-items: center;
    position: relative ;
    background-color: white;
    border-radius: 0.5rem;
    height: 3rem;
    width: 100%;
}

.community-chat-input input{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
}

.community-chat-input input:focus{
    outline: none;
}

.community-chat-input button{
    position: absolute;
    right: 1rem;
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 2.3rem;
    height: 2.3rem;
}

.community-chat-input button i{
    font-size: 2rem;
    font-weight: 600;
}
.community-chat-input input{
    text-align: left;
}