.independent-main{
    height:100%;
    box-sizing: border-box;
}
.independentpage{
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    box-sizing: border-box;
    background-color: #E6F4F7;
    padding: 2rem 6rem;
    min-height: calc(100vh - 19vh);
}
.independent-chat{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 2rem;
    
}

.chats-input{
    width: 60%;
    background-color: white;
    border-radius: 0.5rem;
    border: 0.1rem solid #FFD800;
    height: 3.5rem;
    padding:0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.chats-input form{
    display: flex;
    align-items: center;
}

.chats-input form input{
    border: none;
    height: 100%;
    width: 95%;
}

.chats-input form input:focus{
    outline: none;
}

.chats-input form button i{
    font-size: 2rem;
    font-weight: 600;
}

.chats-input form button{
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 5%;
    height: 100%;
}


.allChats{
    height: 100%;
    overflow-y: hidden;
    width: 100%;
}

::-webkit-scrollbar{
    width: 0;
}

@media (max-width:1025px){

    .chats-input{
    width: 85%;
    background-color: white;
    border-radius: 0.5rem;
    border: 0.1rem solid #FFD800;
    height: 3.5rem;
    padding:0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
    .chats-input form input{
    border: none;
    height: 100%;
    width: 94%;
}

.chats-input form button{
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 6%;
    height: 100%;
}
}

@media (max-width:770px){

    .chats-input{
    width: 85%;
    background-color: white;
    border-radius: 0.5rem;
    border: 0.1rem solid #FFD800;
    height: 3.5rem;
    padding:0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
    .chats-input form input{
    border: none;
    height: 100%;
    width: 92%;
}

.chats-input form button{
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 8%;
    height: 100%;
}
}

@media (max-width:440px){

    .independentpage{
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    box-sizing: border-box;
    background-color: #E6F4F7;
    padding: 2rem;
    min-height: calc(100vh - 19vh);
}

    .chats-input{
    width: 100%;
    background-color: white;
    border-radius: 0.5rem;
    border: 0.1rem solid #FFD800;
    height: 3.5rem;
    padding:0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}
    .chats-input form input{
    border: none;
    height: 100%;
    width: 88%;
}

.chats-input form button{
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 12%;
    height: 100%;
}
}