.studentref{
    box-sizing: border-box;
}

.wellness-main{
    padding: 2rem 6.5rem;
}

.wellness-main .wellness-head{
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
}

.wellness-main .wellness-items-1{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    place-items: center;
    margin-top: 2rem;
    padding: 0rem 5rem;
    
    
}
.wellness-main .wellness-items-2{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    place-items: center;
    margin-top: 1rem;
    padding: 0rem 15rem;
}

.wellness-items-1 .wellness-item,.wellness-items-2 .wellness-item{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    display: flex;
    gap:0.5rem
}

.wellness-item .item-img{
    width: 10rem;
    height: 10rem;
    position: relative;
    cursor: pointer;
}

.wellness-item .item-img .item-progress{
    position: absolute;
    top:0;
    right: 0;
    font-size: 0.8rem;
    font-weight: 600;
    background-color: #FFD800;
    padding: 0.2rem;
    border-radius: 0.5rem;
}
.wellness-item .item-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    border: 0.3rem solid  #FFD800;
}
.sub-menu{
    text-decoration: none;
}
@media(max-width:768px){
    .wellness-items-1{
        grid-template-columns: repeat(3,1fr) !important;
        place-items: start !important;
        padding: 0 !important;
        
    }
    .wellness-items-2{
        grid-template-columns: repeat(2,1fr) !important;
        padding: 0 !important;
        place-items: start !important;
    }
    
}
@media(max-width:440px){
    .wellness-main{
        padding-left: 2rem;
    }
    .wellness-items-1{
        grid-template-columns: repeat(2,1fr) !important;
        place-items: start !important;
        padding: 0 !important;
    }
}