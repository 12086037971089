.action{
    position: relative;
    top:5rem;
    
}
.action-footer{
    position: absolute;
    top:55rem;
    margin: 0;
    padding: 0;
}
.action p{
    width:1300px;
    font-size: 1.3rem;
    text-align: justify;
}
.rectangle{
    display: inline-block;
    width: 500px;
    height: 260px;
    background-color: grey;
    margin: 30px 0;
}
.two{
    transform: translateX(300px);
}
@media(max-width:768px){
    .action p{
        width: 41rem;
    }
    .sub-action{
        display: flex;
        flex-direction: column;
    }
    .two{
        transform: none;
    }
    .action-footer{
        margin-top: 30rem;
    }
}
@media(max-width:440px){
    .action p{
        font-size: 0.9rem;
        width: 25rem ;
    }
    .rectangle{
        width:400px;
    }
}