.reachout{
    position:absolute ;
    top:7rem;
}
.reachout p{
    width:600px;
}
.reachout-button{
    border: none;
    width:9rem;
    background-color: #FFD800;
    height:2.5rem;
    border-radius: 5px;
    font-weight: 500;
    margin-left: 6rem;
    margin-top: 1.5rem;
}
.form-floating{
    background: #E6F4F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 521px;
    height: 554px;
    
    display: flex;
    
    flex-direction: column;
    position: absolute;
    left: 800px;
    
}
.form-floating h3{
    margin: 2.5rem 0 0 0;
}
.form-floating form{
    margin: 1.5rem 0 0 5.5rem;
}
.form-floating form label{
    font-size: 1.3rem;
}
.form-floating form input{
    width: 350px;
    border-radius: 8px;
    border: none;
    
}
.social a i{
    font-size: 4rem;
    color: #FFD800;
}
.reach-container{
    margin-top: 130px;
}
#name{
    height: 40px;
}
#email{
    height: 40px;
}
#message{
    height: 100px;
}
@media(max-width:768px){
    .reachout{
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .reach-container{
        margin-top: 0rem;
        margin-left: 5rem;
    }
    .form-floating{

        left:5rem;
    }
    .reach-footer{
        width:100%;
        margin-top: 70rem !important;
    }
}