.team-container{
    position: absolute;
    width:70%;
    left:15%;
    top:155rem;
}
.team{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    
}
.square{
    display: inline-block;
    width: 200px;
    height: 200px;
    background-color: grey;
    margin: 2rem;
}
.team-content p{
    width: 300px;
    font-size: 1.4rem;
}
@media(max-width:768px){
    .team-cont{
        display:grid;
        grid-template-columns: 1fr 1fr;
    }
    .team-content h4{
        margin-left: 7rem;
    }
}