.lackingwellness-main{
    position: relative;
    width: 100%;

}
.lackingwellness-box{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width:100%;
    height:100%;
    top: 1rem;
    z-index: 100;
}
.lackingwellness-box-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width:50%;
    height:80%;
    background-color: white;
    border-radius: 0.5rem;
    padding: 2rem;
}

.lackingwellness-box-1 button{
    background-color: transparent;
    border:none;
}

.x-mark{
    position: absolute;
    top:1rem;
    right:2rem;
    font-size: 3rem;
}

.box-1-imgs{
    display: flex;
    margin-top: 2rem;
    gap:1rem;
}
.lackingwellnesspage{
    position: relative;
    box-sizing: border-box;
    background-color: #E6F4F7;
    padding: 2rem 6rem;
    height: 40rem;
    opacity: 0.4;
}
.lackingwellness-chat{
    position: absolute;
    bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 2rem;
    width: 90%;
}



.chat-ai{
    display: flex;
    align-items: center;
    gap:1rem;
    justify-content: flex-start;
}
 .chat-user{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:1rem
}

.chat-ai .chat-img img, .chat-user .chat-img img{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
}

.chat-ai .chat-msg, .chat-user .chat-msg{
    width: 30rem;
    height: 5rem;
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    background-color: white;
}

.chat-input{
    display: flex;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    
}

.chat-input .input-box{
    display: flex;
    align-items: center;
    position: relative ;
    background-color: white;
    border-radius: 0.5rem;
    height: 3rem;
    width: 60%;
    border: 0.1rem solid #FFD800;
}

.chat-input input{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
    text-indent: -45rem;
}

.chat-input input:focus{
    outline: none;
}

.chat-input button{
    position: absolute;
    right: 1rem;
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 2.3rem;
    height: 2.3rem;
}

.chat-input button i{
    font-size: 2rem;
    font-weight: 600;
}
@media(max-width:440px){
    .box-1-imgs{
        flex-direction: column;
    }
}