.onboarding input{
    border: 2px solid #FFD800;
    background: #FFFFFF;
    border-radius: 8px;
    width: 350px;
    height: 42px;
}
.onboarding label{
    font-size: 1rem;
    font-weight: 500;
    
}
.onboarding-img{
    width: 2300px;
    height: 1300px;
}
.onboarding{
    background-color: rgba(255,255,255,0.9);
    width: fit-content;
    z-index: 1;
    position: absolute;
    top:40%;
    left: 30%;
    border-radius: 20px;
    padding: 100px;
}
.onboarding h4{
    margin-bottom: 3rem;
}
.onboarding button{
    width: 140px;
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
    margin-top: 2rem;
}
.xx{
    border: 2px solid #FFD800;
    padding-top: 4px;
    box-sizing: 0;
    margin: 0;
    height: 42px;
    border-radius: 8px;
    width:70px  !important;
    cursor: pointer;
}
.onboarding select{
    border: 2px solid #FFD800;
    background-color: #fff;
    border-radius: 8px;
    width: 350px;
    height: 42px;
}
 
.btn-disabled{
    background-color: #fff !important;
    cursor: not-allowed ! important;
    
}

.text-start input:focus{
    outline:none;
}

.bg-yellow{
    background-color:#FFD800 ;
}
@media(max-width:768px){
    .onboarding-img{
        width: 770px;
    }
    .onboarding{left: 4%; right: 3%;}
}
@media(max-width:440px){
    .onboarding-img{
        display: none;
    }
    .onboarding{
        top:10%;
        left:0%;
        right:1%;
        background: none;
        padding-left: 2rem;
        padding-right: 1rem;
    }
    .onboarding-foot{
        margin-top: 65rem;
    }
    .board{
        display: flex !important;
        flex-direction: column !important;
    }
}