*{
    text-decoration: none;
}

.nav-bar{
    height: 10vh !important;
}

.nav-bar nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 3rem;
}

nav .nav-items{
    display: flex;
    gap: 2rem;
}

nav .nav-item{
    text-decoration: none;
    color: black;
    font-size: 1.1rem;
}

@media(max-width:770px){
    nav .nav-item{
    text-decoration: none;
    color: black;
    font-size: 0.8rem;
}
}

@media(max-width:440px){
    .nav-bar nav{
        margin: 0;
    }
    nav .nav-item{
    text-decoration: none;
    color: black;
    font-size: 0.6rem;
}
    nav .nav-items{
    display: flex;
    gap: 0.4rem;
}

nav .logo-img img{
    width:3rem !important ;
}
.logo-img{
    margin: 2rem ;
}
.nav-items{
    margin-right: 2rem;
}

}
 