.student-profile{
    background: #E6F4F7;
    padding: 6rem;
    height: 45rem;
    
}
.student-box{
    width:1000px;
    height: 30rem;
    background-color: #F8F7F4;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
    border-radius: 20px;
}
.profilediv{
    margin: 6rem 3rem 0 4rem;
}
.profile-img{
    width:10rem;
    height: 9rem;
    border-radius: 50%;
    margin: 1.5rem;
    object-fit: cover;
}
.edit-btn{
    width: 140px;
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
}
.vl{
    border-left: 1px solid gray;
    margin:0 2rem 0 2rem
}
.submain{
     margin-top: 2rem;
}
.main{
    margin: 2rem 0 1.5rem 1.5rem;
    gap: 4rem;
}
.student-profile input{
    border: 2px solid #FFD800;
    background: #FFFFFF;
    border-radius: 8px;
    width: 350px;
    height: 42px;
}
.student-profile select{
    border: 2px solid #FFD800;
    background-color: #fff;
    border-radius: 8px;
    width: 350px;
    height: 42px;
}
@media(max-width:768px){
    .student-profile{
        height: 60rem;
    }
    .student-box{
        width: 600px;
        display: flex;
        flex-direction: column;
        height: 52rem;
    }
    .vl{
        border-bottom: 2px solid black;
        margin-top: 1rem;
    }
    .main{
        margin-left: 5rem;
    }
}
@media(max-width:440px){
    .student-box{
        background: none;
        box-shadow: none;
        width:400px;
    }
    .student-profile{
        padding-top: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .vl{
        display: none;
    }
    .main{
        display: flex !important;
        margin-left: 0  !important;
    }
    .student-profile input{
        width: 250px;
        
    }
    .student-profile select{
        width: 250px;
    }
}