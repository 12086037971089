
.dashboard-container {
    position: relative;
    width: 97%;
    height:70%;
    /* left: 20%; */
}

.rect-container {
    position: relative;
    height:675px;
    overflow: hidden;
}
.main-footer{
    position: relative ;
    bottom: 0;
    left: 0;
    width: 100%; 
    background-color: #232B2B;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
 
    
}
.footer-link{
    color:white;
    outline: none;
    text-decoration: none;
}
.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.footer div{
    column-gap: 10px;
}
.vline{
    border-left:2px solid white;
    height:20px;
}
