.chatsAi .chat-ai{
    display: flex;
    align-items: center;
    gap:1rem;
    justify-content: flex-start;
}
 .chatsAi .chat-user{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:1rem
}
.chat-msg{
    width: fit-content !important;
    height: fit-content !important;
    padding: 1.5rem;
}

.chat-msg p{
    text-align: justify !important;
}

.chatsAi .chat-ai .chat-img img, .chatsAi .chat-user .chat-img img{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
}

.chatsAi .chat-ai .chat-msg, .chatsAi .chat-user .chat-msg{
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    background-color: white;
}
.blinking-dot {
    width: 20px;
    height: 20px;
    background-color: black;
    border-radius: 50%;
    animation: blink 1s infinite alternate;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@media (max-width:420px){
    .chatsAi .chat-ai .chat-img img, .chatsAi .chat-user .chat-img img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    object-fit: cover;
}
}