.verify-img{
    width: 2300px;
    height: 950px;
}
.verify{
    margin: 7rem 2rem 0 5rem;
}
.verify input{
    
    width:40px;
    height:55px;
    text-align: center;
    margin: auto;
    background-color: white;
    font-size: 1.8rem;
}
.logindiv div{
    width: 500px;
}
.submit{
    width: 140px;
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
    
}
.logindiv{
    background-color: #fff;
    width: 800px;
    height:600px;
    opacity: 90%;
    z-index: 1;
    position: absolute;
    top:45%;
    left: 30%;
    border-radius: 20px;
    padding: 70px;
}
@media(max-width:768px){
    .verify-img{
        width:770px;
    }
    .logindiv{
        width: 600px;
        left: 10%;
    }
    .verify{
        margin-left: 0rem;
    }
}
@media(max-width:440px){
    
    .verify-img{
        display: none;
    }
    .logindiv{
        background: none;
        padding: 0;
        width: 400px;
    }
    .verify-foot{
        margin-top: 40rem;
    }
    .logindiv h4{
        margin-bottom: 1rem;
    }
    .verify{
        margin:0;
        width:370px !important;
    }
    .illustrator{
        width:300px
    }

}