.sumit-img{
    width: 2300px;
    height: 950px;
}
@media(max-width:768px){
    .sumit-img{
        width:770px;
        
    }
    .crc-p{
        width: 450px;
    }
}
@media(max-width:440px){
    .sumit-img{
        display: none;
    }
    .crc-p{
        width: 300px;
    }
    .crc-sumit{
        margin-top: 40rem;
    }
}