.forget img{
    width: 2300px;
    height: 950px;
}
.forget-div-1{
    background-color: #fff;
    width: fit-content;
    opacity: 90%;
    z-index: 1;
    position: absolute;
    top:45%;
    left: 35%;
    border-radius: 20px;
    padding: 70px;
    display: flex;
    flex-direction: column;
    gap:30px
}
.forget-div-1 label{
    font-size: 1rem;
    font-weight: 700;
}
.forget-div-1 input{
    border: 2px solid #FFD800;
    background-color: #fff;
    border-radius: 8px;
    width: 350px;
    height: 35px;
}
.forget-div-1 button{
    background-color: #FFD800;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: none;
    padding: 3px;
}