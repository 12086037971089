.sidepanel{
    background: #FFD800;
    width: 20rem;
    padding: 3rem;
    height:52rem;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
 
}
.sidepanel-box{
    background-color: #ffffff;
    width: 250px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: relative;
    height: 3.5rem;
    margin-bottom: 1.5rem;
}
.sidepanel-box div{
    background-color: #000000;
    color: #ffffff;
    width: 47px;
    position: absolute;
    right: 0;
    height: 100%;
    border-radius: 8px;
    font-weight: 500;
}
.sidepanel-box p{
    color: #000000;
    font-size: 1rem;
    font-weight: 500;
    
}
.sidepanel-menu{
    margin: 0;
    position: absolute;
    top:0;
    
}
@media(max-width:768px){

}