*{
    margin: 0;
    padding: 0;
    text-align: center;
}
.text-danger{
    text-align: left;
}

.home-div1 img{
    width: 1550px;
}
.home-div2{
    background-color: #FFD800;
    display: flex;
    flex-direction: column;
    gap:2rem;
    align-items: center;
    padding: 2rem;
}
.home-div2 img{
    width: 350px;
    margin: 6rem auto;
}
.home-div2 p{
    width: 390px;
    font-size: 1.6rem;
    line-height: 3rem;
    margin: 0 auto;
    text-align: justify!important;
}
.b-1{
    margin: 2rem 10rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.b-2{
    margin: 1rem 10rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.wellness-2{
    position: relative;
}

.wellness-2 .wellness-2-img{
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 18rem 0rem;
}

.wellness-2-img img{
    width: 30rem;
}

.all-wellness{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    position: absolute;
    top: 2.5rem;
    width: 100%;
}

.grid-2-center{
    display: flex;
    justify-content: center;
    grid-column: span 2;
}

.grid-mid{
    position: absolute;
    left: 18%;
}
.grid-mid-rev{
    position: absolute;
    right: -35%;
}
.grid-mid-2{
    position: absolute;
    left: 10%;
}

.grid-mid-2-rev{
    position: absolute;
    right: -42.5%;
}
.grid-end-1{
    display: flex;
    justify-content: flex-end;
}
.grid-end-2{
    display: flex;
    justify-content: flex-start;
}

.wellness-main-box{
    width:100%;
    position: relative;
}

.wellness-box{
    display: flex;
    flex-direction: column;
    gap:0.8rem;
    justify-content: center;
    width: 22rem;
    height: 11.5rem;
    background-color: white;
    border: 0.1rem solid #FFD800;
    border-radius: 0.5rem;
    padding: 0.5rem;
    z-index: 2;
}
.wellness-box:hover {
    
    background-color: #FFD800;
    border: none;
    cursor: pointer;
}

.wellness-head{
    font-weight: 600;
    font-size: 1.2rem;
}


.home-testimonials {
    background-color: #E6F4F7;
    width: 100%;
    padding: 1rem;
    margin-top: 3rem;
}

.testimonials-box {
    width: fit-content;
    background: #ffffff;
    padding: 2%;
    margin: auto;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: 25rem;


}

.testimonials-box img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

.testimonials-box h5 {
    font-size: 1.2em;
    font-weight: bold;
}

.testimonials-box h6 {
    font-size: 1em;
    font-style: italic;
}

.testimonials-box p {
    width: 250px;

}

.testimonials-content {
    gap: 2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;

}

.testimonials-content label {
    font-weight: 500;
    font-size: 1.3rem;
}

.testimonials-content input {
    width: 55rem;
    background-color: #ffffff;
    border-radius: 8px;
    border: none;
}

.sub-content {
    justify-content: space-around;
    display: flex;
    gap: 1rem;
}

.name-input {
    height: 3rem;
}

.review-input {
    height: 6rem;
    margin-right: 6px;
}
.testimonials-button{
    border: none;
    width:12rem;
    background-color: #FFD800;
    height:3rem;
    border-radius: 5px;
    font-weight: 500;
}

.home-footer {
    position: relative;
    margin-top: 125rem;
}

@media(max-width:1025px) {
    .home-div2 img {
        width: 15rem;
        margin: 1rem;
    }

    .home-div2 p {
        margin-top: 2rem;
        width: 15rem;
        font-size: 1.5rem;
        line-height: 2.2rem;
        text-align: left !important;
    }

    .b-1 {
        margin: 3rem 1rem;
    }

    .b-2 {
        margin: 1rem 1rem;
    }

    .wellness-box {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        justify-content: center;
        width: 14rem;
        height: 9rem;
        background-color: white;
        border: 0.1rem solid #FFD800;
        border-radius: 0.5rem;
        padding: 0.5rem;
        z-index: 2;
    }

    .wellness-head {
        font-weight: 600;
        font-size: 1rem;
    }

    .wellness-content {
        font-size: 0.7rem;
    }

    .all-wellness {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        position: absolute;
        top: 2rem;
        width: 100%;
    }

    .wellness-2-img img {
        width: 22.5rem;
    }

    .wellness-2 .wellness-2-img {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 14rem 0rem;
    }

}

@media (max-width:830px) {
    .wellness-box {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        justify-content: center;
        width: 12rem;
        height: 6rem;
        background-color: white;
        border: 0.1rem solid #FFD800;
        border-radius: 0.5rem;
        padding: 0.5rem;
        z-index: 2;
    }

    .wellness-head {
        font-weight: 600;
        font-size: 0.7rem;
    }

    .wellness-content {
        font-size: 0.5rem;
    }

    .wellness-2-img img {
        width: 15rem;
    }

    .wellness-2 .wellness-2-img {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 11rem 0rem;
    }

    .testimonials-box {
        width: 14rem;
        background: #ffffff;
        padding: 0.5rem;
        margin: auto;
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        height: 10rem;


    }

    .testimonials-box p {
        font-size: 0.75rem;
        text-align: justify;
        width: auto;

    }

}

@media (max-width:770px) {
    .home-intro {
        overflow: hidden;
    }

    .home-div1 img {

        width: 1051px;
    }

    .home-div2 img {
        width: 10rem;
        margin: 1rem;
    }

    .home-div2 {
        height: 100%;
        overflow: hidden;
    }

    .home-div2 p {
        margin-top: 1rem;
        width: 13rem;
        font-size: 1.1rem;
        line-height: 1.5rem;
        text-align: left !important;
    }
}

@media(max-width:440px) {
    .home-intro {
        display: grid;
        grid-column: auto;
    }

    .home-div1 img {
        width: 500px !important;
    }

    .home-div2 {
        width: 500px;

    }

    .home-div2 p {
        width: 17rem;
        margin-top: 0.5rem;
    }

    .home-div2 button {
        margin-top: 0;
        margin-bottom: 0;
    }

    .testimonials-box p {
        font-size: 8px;
    }

    .wellness-head {
        font-size: 7px;
    }

    .wellness-content {
        font-size: 6px;
    }

    .wellness-box {
        width: 6rem !important;
    }

    .wellness-2-img img {
        width: 10rem
    }

    .wellness-2-img h4 {
        font-size: 10px !important;
    }

    .home-testimonials {
        margin-top: 7rem;
    }
}