
.about-cont{
   position: absolute;
   top:11rem;
}

.about-cont p{
    font-size: 3rem;
    font-weight: 700;
    transform: translateX(200px);
}
.rect {
    display: inline-block;
    width: 400px;
    height: 200px;
    border: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: absolute;
    text-align: center;
    
}
.action-content{
    
    position: relative;
    height: 100%;
    flex-direction: row;
    align-self: center;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

}
.action-content>span{
    position: relative;
    display: inline-block;
    height: 50%;
    /* top:12%; */
}

.yellow1{
    background-color: #FFD800;
    transform: translate(230px,150px);

}
.d-blue1{
    background-color: #68D3FF;
    transform: translate(1000px,400px) ;
}
.blue1{
    background-color: #BDEEFF;
    transform: translate(350px,650px);
}
.yellow2{
    background-color: #FFD800;
    transform: translate(550px,1040px);
}
.d-blue2{
    background-color: #68D3FF;
    transform: translate(1000px,1350px);
}
.blue2{
    background-color: #BDEEFF;
    transform: translate(400px,1600px);
}
.line1{
    width: 600px;
    height: 90px;
    z-index: -1;
    background: transparent;
    border: none;
    border-top: dashed 3px black;
    transform: translate(56.8rem,330px) rotate(30deg);
    margin: 0;
    padding: 0;
    border-radius: 50%/100px 100px 0 0;
    
}
.line2{
    width: 450px;
    height:60px;
    z-index: -1;
    background: transparent;
    border: none;
    border-top: dashed 2px black;
    transform: translate(56rem,710px) rotate(-30deg);
    margin: 0;
    padding: 0;
    
    
}
.line3{
    width: 450px;
    height:60px;
    z-index: -1;
    background: transparent;
    border: none;
    border-top: dashed 2px black;
    transform: translate(40rem,960px) rotate(30deg);
    
}
.line4{
    width: 400px;
    height:60px;
    z-index: -1;
    background: transparent;
    border: none;
    border-top: dashed 2px black;
    transform: translate(70rem,1330px) rotate(30deg);
}
.line5{
    width: 450px;
    height:60px;
    z-index: -1;
    background: transparent;
    border: none;
    border-top: dashed 2px black;
    transform: translate(60rem,1660px) rotate(-30deg);
}
.about-footer{
    position: relative;
    margin-top: 215em;
}
@media (max-width:768px) {
    .about-cont p{
        font-size: 1.5rem;
        transform: translateX(-100px);
    }
    .rect{
        display: inline-block;
        width: 200px;
        height: 100px;
    
    }
    .about-footer{
        position: relative;
        margin-top: 220rem;
    }
    
}