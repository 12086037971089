*{
    text-decoration: none;
}
.nav-bar nav{
    margin: 0rem 3rem 1rem 5rem;
    
}

.nav-bar nav div img{
    width: 90px;
    margin-bottom: 7px;
}
.nav-bar nav div ul a{
    text-decoration: none;
    color: black;
}

.dropdown{
    position: relative;
}
.dropdown-content {
    display: none;
    position: absolute;
    top:100%;
    left: 0;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 1rem;
    z-index: 1;
    background: #232B2B;
    font-size: 0.8rem;
    
  }
  .dropdown-content p{
    color: #fff;
  }
.dropdown:hover .dropdown-content {
    display: block;
} 
@media(max-width:768px){
    .dropdown-content{
        padding: 0.5rem!important;
        font-size: 0.6rem !important;
    }
}
@media(max-width:440px){
    .nav-bar nav{
        margin-top: 0;
    }
    .nav-bar{
        background: #FFD800;
    }
}

 