.main-wellness{
    background: #F9F4DC;
    margin-top: 3rem;
    position: relative;
}
.spiritual-chat-ai .spiritual-chat-img img, .spiritual-chat-user .spiritual-chat-img img{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    
}
.spiritual-chat-ai{
    display: flex;
    align-items: center;
    gap:3rem;
    justify-content: flex-start;
}
.spiritual-chat-user{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:3rem
}

.spiritual-chat-input{
    display: flex;
    margin-top: 18rem;
    align-items: center;
    justify-content: center;
    
}
.spiritual-chat-ai .spiritual-chat-msg, .spiritual-chat-user .spiritual-chat-msg{
    width: 30rem;
    height: 5rem;
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    background-color: white;
}
.spiritual-chat-input .spiritual-input-box{
    display: flex;
    align-items: center;
    position: relative ;
    background-color: white;
    border-radius: 0.5rem;
    height: 3rem;
    width: 60%;
    border: 0.1rem solid #FFD800;
}

.spiritual-chat-input input{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0.5rem;
    text-indent: -45rem;
}

.spiritual-chat-input input:focus{
    outline: none;
}

.spiritual-chat-input button{
    position: absolute;
    right: 1rem;
    background-color: black;
    color: white;
    border-radius: 50%;
    border: none;
    width: 2.3rem;
    height: 2.3rem;
}

.spiritual-chat-input button i{
    font-size: 2rem;
    font-weight: 600;
}
.client{
    background-color:  #E6F4F7 !important;
}
.user{
    background-color: #BDEEFF !important;
}
.floating-support{
    width:fit-content;
    padding: 60px;
    height: 35rem;
    background-color: #ffffff;
    border-radius: 20px;
    position:absolute;
    bottom: 8rem;
    left:40rem;
    display: none;
}
.floating-support h5{
    width: 600px;
    margin-top: 6rem;
    margin-bottom: 2rem;
}
.floating-support div{
    display: flex;
    flex-direction: column;
    gap:2rem;
    align-items: center;
    justify-content: center;
}
.cross{
    background: transparent;
    border: none;
}