.App {
  text-align: center;
}

*{
  text-decoration: none;
  margin: 0;
  padding: 0;

}
input{
  text-align: left;
 
}

body{
  overflow-x: hidden;
  background-color: #F8F7F4;
  
}
